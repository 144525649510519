import React, { useState, useEffect } from 'react';
import { fetchSicknessLogs, fetchUsers, createSicknessLog, updateSicknessLog, deleteSicknessLog } from './api';
import { toast } from 'react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';

function SicknessLogs() {
  const [logs, setLogs] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newLog, setNewLog] = useState({
    user_id: '',
    days: 1,
    reason: '',
    sick_date: '' // New field for sick date
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [logToEdit, setLogToEdit] = useState(null);

  const fetchAllLogs = async () => {
    try {
      const response = await fetchSicknessLogs();
      setLogs(response.data);
      setFilteredLogs(response.data);
    } catch (error) {
      toast.error('Failed to fetch logs');
    }
  };

  const fetchAllUsers = async () => {
    try {
      const response = await fetchUsers();
      setUsers(response.data);
    } catch (error) {
      toast.error('Failed to fetch users');
    }
  };

  useEffect(() => {
    fetchAllLogs();
    fetchAllUsers();
  }, []);

  useEffect(() => {
    const results = logs.filter(log => {
      const formattedDate = formatDate(log.sick_date); // Format sick_date for comparison
      return (
        log.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        log.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        log.reason.toLowerCase().includes(searchTerm.toLowerCase()) ||
        formattedDate.includes(searchTerm) // Compare formatted date with searchTerm
      );
    });
    setFilteredLogs(results);
  }, [searchTerm, logs]);
  const handleInputChange = (field, value) => {
    setNewLog({ ...newLog, [field]: value });
  };
  const formatDateForInput = (dateString) => {
    if (!dateString) return ''; // Handle case where date might be null or undefined
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const handleCreateLog = async () => {
    try {
      await createSicknessLog(newLog);
      fetchAllLogs();
      toast.success('Log created successfully');
      setIsModalOpen(false);
    } catch (error) {
      toast.error('Failed to create log');
    }
  };

  const handleEditLog = async () => {
    try {
      await updateSicknessLog(logToEdit.id, logToEdit);
      fetchAllLogs();
      toast.success('Log updated successfully');
      setIsEditModalOpen(false);
    } catch (error) {
      toast.error('Failed to update log');
    }
  };

  const handleDeleteLog = async (id) => {
    try {
      await deleteSicknessLog(id);
      fetchAllLogs();
      toast.success('Log deleted successfully');
    } catch (error) {
      toast.error('Failed to delete log');
    }
  };

  // Transform user data for react-select
  const userOptions = users.map(user => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`
  }));

  // Helper to format dates
  const formatDate = (date) => {
    const d = new Date(date);
    return `${String(d.getDate()).padStart(2, '0')}/${String(d.getMonth() + 1).padStart(2, '0')}/${d.getFullYear()}`;
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-semibold mb-6 text-center">Manage Sickness Logs</h1>
      
      <div className="flex justify-between items-center mb-6">
        <input
          type="text"
          placeholder="Search by name or reason or date"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-1/3 px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
        />
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Create Log
        </button>
      </div>

      <div className="w-full max-w-full">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md table-auto">
          <thead className="bg-gold">
            <tr>
              <th className="px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Name</th>
              <th className="px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Sick Date</th>
              <th className="px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Days</th>
              <th className="px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Reason</th>
              <th className="px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {filteredLogs.map(log => (
              <tr key={log.id}>
                <td className="px-2 py-4 whitespace-nowrap">{log.firstName} {log.lastName}</td>
                <td className="px-2 py-4 whitespace-nowrap">{formatDate(log.sick_date)}</td>
                <td className="px-2 py-4 whitespace-nowrap">{log.days}</td>
                <td className="px-2 py-4 whitespace-nowrap">{log.reason}</td>
                <td className="px-2 py-4 whitespace-nowrap flex space-x-2">
                  <button
                    onClick={() => { setLogToEdit(log); setIsEditModalOpen(true); }}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteLog(log.id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for creating a log */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Create New Sickness Log</h2>
            <div className="mb-4">
              <label className="block text-gray-700">User</label>
              <Select
                options={userOptions}
                value={userOptions.find(option => option.value === newLog.user_id)}
                onChange={(option) => handleInputChange('user_id', option.value)}
                className="w-full"
                isSearchable
                placeholder="Search and select user"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Sick Date</label>
              <input
                type="date"
                value={newLog.sick_date}
                onChange={(e) => handleInputChange('sick_date', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Days</label>
              <input
                type="number"
                value={newLog.days}
                onChange={(e) => handleInputChange('days', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Reason</label>
              <input
                type="text"
                value={newLog.reason}
                onChange={(e) => handleInputChange('reason', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateLog}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for editing a log */}
      {isEditModalOpen && logToEdit && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Edit Sickness Log</h2>
            <div className="mb-4">
              <label className="block text-gray-700">User</label>
              <p className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm bg-gray-100">
                {logToEdit.firstName} {logToEdit.lastName}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Sick Date</label>
              <input
                type="date"
                value={formatDateForInput(logToEdit.sick_date)}
                onChange={(e) => setLogToEdit({ ...logToEdit, sick_date: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Days</label>
              <input
                type="number"
                value={logToEdit.days}
                onChange={(e) => setLogToEdit({ ...logToEdit, days: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Reason</label>
              <input
                type="text"
                value={logToEdit.reason}
                onChange={(e) => setLogToEdit({ ...logToEdit, reason: e.target.value })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsEditModalOpen(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleEditLog}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SicknessLogs;
