import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { fetchUsers, addHolidayRequest } from './api';

function AddHolidayRequest() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startDayType, setStartDayType] = useState('full');
  const [endDayType, setEndDayType] = useState('full');
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const response = await fetchUsers();
        setUsers(response.data);
        setFilteredUsers(response.data);
      } catch (error) {
        toast.error('Failed to fetch users');
      }
    };
    loadUsers();
  }, []);

  // Filter users based on search term
  useEffect(() => {
    const filtered = users.filter(user => 
      `${user.firstName} ${user.lastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [searchTerm, users]);

  const handleUserSelect = (user) => {
    setSelectedUser(user.id);
    setSearchTerm(`${user.firstName} ${user.lastName}`);
    setShowDropdown(false); // Close dropdown after selection
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addHolidayRequest({
        userId: selectedUser,
        startDate,
        endDate,
        startDayType,
        endDayType,
      });
      toast.success('Holiday request added and vacation hours deducted successfully');
      setSelectedUser(null);
      setStartDate('');
      setEndDate('');
      setStartDayType('full');
      setEndDayType('full');
      setSearchTerm('');
    } catch (error) {
      toast.error('Failed to add holiday request');
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-semibold mb-6 text-center">Add Holiday Request for User</h1>
      <form onSubmit={handleSubmit} className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md">
        <div className="mb-4 relative">
          <label className="block text-gray-700 text-sm font-bold mb-2">Select User</label>
          <input
            type="text"
            placeholder="Type to search for a user"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setShowDropdown(true);
            }}
            className="border rounded w-full px-3 py-2 mb-2"
            onFocus={() => setShowDropdown(true)}
          />
          
          {showDropdown && filteredUsers.length > 0 && (
            <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded shadow-md max-h-40 overflow-y-auto">
              {filteredUsers.map((user) => (
                <li
                  key={user.id}
                  onClick={() => handleUserSelect(user)}
                  className="px-4 py-2 hover:bg-blue-500 hover:text-white cursor-pointer"
                >
                  {user.firstName} {user.lastName}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Start Date</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
            className="border rounded w-full px-3 py-2"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Start Day Type</label>
          <select
            value={startDayType}
            onChange={(e) => setStartDayType(e.target.value)}
            className="border rounded w-full px-3 py-2"
          >
            <option value="full">Full Day</option>
            <option value="morning">Morning</option>
            <option value="afternoon">Afternoon</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">End Date</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required
            className="border rounded w-full px-3 py-2"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">End Day Type</label>
          <select
            value={endDayType}
            onChange={(e) => setEndDayType(e.target.value)}
            className="border rounded w-full px-3 py-2"
          >
            <option value="full">Full Day</option>
            <option value="morning">Morning</option>
            <option value="afternoon">Afternoon</option>
          </select>
        </div>

        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add Holiday Request
        </button>
      </form>
    </div>
  );
}

export default AddHolidayRequest;
