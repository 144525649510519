import React, { useState, useEffect } from 'react';
import { fetchDepartments, updateDepartment, deleteDepartment, createDepartment } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Departments() {
  const [departments, setDepartments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newDepartment, setNewDepartment] = useState({
    name: ''
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [departmentToDelete, setDepartmentToDelete] = useState(null);

  // Fetch all departments from the server
  const fetchAllDepartments = async () => {
    try {
      const response = await fetchDepartments();
      setDepartments(response.data);
    } catch (error) {
      toast.error('Failed to fetch departments');
    }
  };

  useEffect(() => {
    fetchAllDepartments();
  }, []);

  const handleInputChange = (index, field, value) => {
    const updatedDepartments = [...departments];
    updatedDepartments[index][field] = value;
    setDepartments(updatedDepartments);
  };

  const handleSave = async (id, department) => {
    try {
      await updateDepartment(id, department);
      toast.success(`Department ${department.name} updated successfully`);
    } catch (error) {
      toast.error('Failed to update department');
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDepartment(id);
      setDepartments(departments.filter(department => department.id !== id));
      toast.success(`Department deleted successfully`);
    } catch (error) {
      toast.error('Failed to delete department');
    }
  };

  const handleNewDepartmentInputChange = (field, value) => {
    setNewDepartment({ ...newDepartment, [field]: value });
  };

  const handleCreateDepartment = async () => {
    try {
      await createDepartment(newDepartment);
      fetchAllDepartments();
      toast.success(`Department ${newDepartment.name} created successfully`);
      setIsModalOpen(false);
    } catch (error) {
      toast.error('Failed to create department');
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-semibold mb-6 text-center">Manage Departments</h1>

      <div className="flex justify-between items-center mb-6">
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Create Department
        </button>
      </div>

      <div className="w-full max-w-full">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md table-auto">
          <thead className="bg-gold">
            <tr>
              <th className="px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Department Name</th>
              <th className="px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {departments.map((department, index) => (
              <tr key={department.id} className="hover:bg-gray-100">
                <td className="px-2 py-4 whitespace-nowrap">
                  <input
                    type="text"
                    value={department.name}
                    onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                    className="text-sm font-medium text-gray-900 border border-gray-300 rounded px-2 py-1"
                  />
                </td>
                <td className="px-2 py-4 whitespace-nowrap flex space-x-2">
                  <button
                    onClick={() => handleSave(department.id, department)}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => {
                      setDepartmentToDelete(department);
                      setIsDeleteModalOpen(true);
                    }}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for deleting department */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Confirm deletion</h2>
            <p>Do you really want to delete the department '{departmentToDelete?.name}'?</p>
            <div className="flex justify-end space-x-4 mt-4">
              <button
                onClick={() => setIsDeleteModalOpen(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleDelete(departmentToDelete.id);
                  setIsDeleteModalOpen(false);
                }}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for creating new department */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Create New Department</h2>
            <div className="mb-4">
              <input
                type="text"
                placeholder="Department Name"
                value={newDepartment.name}
                onChange={(e) => handleNewDepartmentInputChange('name', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateDepartment}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Departments;
