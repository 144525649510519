import React, { useState, useEffect } from 'react';
import { fetchVacationDays, submitHolidayRequest } from './api';
import { toast } from 'react-toastify';

function HolidaysRequest() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [vacationHours, setVacationHours] = useState(0);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [startDayType, setStartDayType] = useState('full'); // full, morning, afternoon
  const [endDayType, setEndDayType] = useState('full'); // full, morning, afternoon

  useEffect(() => {
    const getVacationDays = async () => {
      try {
        const response = await fetchVacationDays();
        setVacationHours(response.data.vacation_hours);
      } catch (error) {
        toast.error('Failed to fetch vacation hours');
      }
    };
    getVacationDays();
  }, []);
  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    setStartDate(selectedStartDate);
  
    // Mettre à jour la restriction minimale pour la date de fin
    if (new Date(selectedStartDate) > new Date(endDate)) {
      setEndDate(selectedStartDate);
      setMessage(''); // Réinitialiser le message si les dates deviennent valides
    }
  };
  
  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
  
    if (new Date(selectedEndDate) < new Date(startDate)) {
      setStartDate(selectedEndDate);
      setMessage(''); // Réinitialiser le message si les dates deviennent valides
    }
  
    setEndDate(selectedEndDate);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await submitHolidayRequest( startDate, endDate, startDayType, endDayType );
      setMessage('Holiday request submitted successfully');
      toast.success('Holiday request submitted successfully');
    } catch (error) {
      setMessage('Failed to submit holiday request');
      toast.error('Failed to submit holiday request');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center bg-gray-100 py-12 px-4">
      <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
        <h1 className="text-3xl font-semibold mb-6 text-center">Request Holidays</h1>
        <form onSubmit={handleSubmit}>
          <p className="mb-4 p-4 bg-gold text-white text-lg font-bold rounded text-center">
            Please note: The start date is the first day of your holiday, and the end date is included as the last day of your holiday.
          </p>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
            <div className="mt-2">
              <label>
                <input
                  type="radio"
                  value="full"
                  checked={startDayType === 'full'}
                  onChange={() => setStartDayType('full')}
                />
                Full Day
              </label>
              <label className="ml-4">
                <input
                  type="radio"
                  value="morning"
                  checked={startDayType === 'morning'}
                  onChange={() => setStartDayType('morning')}
                />
                Morning
              </label>
              <label className="ml-4">
                <input
                  type="radio"
                  value="afternoon"
                  checked={startDayType === 'afternoon'}
                  onChange={() => setStartDayType('afternoon')}
                />
                Afternoon
              </label>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">End Date (included):</label>
            <input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              min={startDate || new Date().toISOString().split('T')[0]} // Bloquer les dates avant la date de début
              className="w-full px-3 py-2 border rounded"
              required
              disabled={!startDate}
            />
            <div className="mt-2">
              <label>
                <input
                  type="radio"
                  value="full"
                  checked={endDayType === 'full'}
                  onChange={() => setEndDayType('full')}
                  disabled={!endDate}
                />
                Full Day
              </label>
              <label className="ml-4">
                <input
                  type="radio"
                  value="morning"
                  checked={endDayType === 'morning'}
                  onChange={() => setEndDayType('morning')}
                  disabled={!endDate}
                />
                Morning
              </label>
              <label className="ml-4">
                <input
                  type="radio"
                  value="afternoon"
                  checked={endDayType === 'afternoon'}
                  onChange={() => setEndDayType('afternoon')}
                  disabled={!endDate}
                />
                Afternoon
              </label>
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-gold text-white py-2 px-4 rounded hover:opacity-85"
            disabled={loading || !startDate || !endDate}
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                </svg>
                Submitting...
              </div>
            ) : (
              'Submit Request'
            )}
          </button>
        </form>
        {message && <p className="mt-4 text-center text-red-500">{message}</p>}
        <div className="mt-4 text-center">
          <p className="text-gray-700">You have {vacationHours} vacation hours available.</p>
        </div>
      </div>
    </div>
  );
}

export default HolidaysRequest;
