import React, { useState, useEffect } from 'react';
import { fetchUsers, updateUser, deleteUser, createUser, fetchDepartments } from './api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Users() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    vacation_hours: 160,
    department_id: '',
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [departments, setDepartments] = useState([]);

  // Fonction pour charger les départements
  const fetchAllDepartments = async () => {
    try {
      const response = await fetchDepartments();
      setDepartments(response.data);
    } catch (error) {
      toast.error('Failed to fetch departments');
    }
  };

  // Fonction pour charger les utilisateurs et faire correspondre `department` avec `department_id`
  const fetchAllUsers = async () => {
    try {
      const response = await fetchUsers();
      const updatedUsers = response.data.map(user => {
        const department = departments.find(dept => dept.name === user.department) || {};
        return { ...user, department_id: department.id || null }; // Ajoute department_id pour chaque utilisateur
      });
      setUsers(updatedUsers);
      setFilteredUsers(updatedUsers);
    } catch (error) {
      toast.error('Failed to fetch users');
    }
  };

  useEffect(() => {
    fetchAllDepartments();
  }, []);
  useEffect(() => {
    if (departments.length > 0) {
      fetchAllUsers();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departments]);
  useEffect(() => {
    const results = users.filter((user) =>
      (user.firstName && user.firstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (user.lastName && user.lastName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredUsers(results);
  }, [searchTerm, users]);



  const handleInputChange = (index, field, value) => {
    const updatedUsers = [...filteredUsers];
    if (field === 'department_id') {
      const selectedDepartment = departments.find(department => department.id === parseInt(value));
      updatedUsers[index]['department'] = selectedDepartment ? selectedDepartment.name : 'No Department';
    }
  
    updatedUsers[index][field] = value;
    setFilteredUsers(updatedUsers);
  };

  const departmentColors = {
    Production: 'bg-green-100',
    Cleaning: 'bg-gray-100',
    Directors: 'bg-teal-100',
    Engineers: 'bg-yellow-100',
    Floor: 'bg-orange-100',
    G3: 'bg-cyan-100',
    Office: 'bg-red-100',
    Process: 'bg-pink-100',
    QC: 'bg-blue-900',
    Warehouse: 'bg-purple-100'
  };

  const handleSave = async (id, user) => {
    try {
      await updateUser(id, user);
      toast.success(`User ${user.email} updated successfully`);
      fetchAllUsers(); // Re-fetch users after update
    } catch (error) {
      toast.error('Failed to update user');
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteUser(id);
      setUsers(users.filter((user) => user.id !== id));
      toast.success(`User deleted successfully`);
      setIsDeleteModalOpen(false);
    } catch (error) {
      toast.error('Failed to delete user');
    }
  };

  const handleNewUserInputChange = (field, value) => {
    setNewUser({ ...newUser, [field]: value });
  };

  const handleCreateUser = async () => {
    try {
      await createUser(newUser);
      fetchAllUsers();
      toast.success(`User ${newUser.email} created successfully`);
      setIsModalOpen(false);
    } catch (error) {
      toast.error('Failed to create user');
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-semibold mb-6 text-center">Manage Users</h1>

      <div className="flex justify-between items-center mb-6">
        <input
          type="text"
          placeholder="Search by name or email"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-1/3 px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
        />
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Create User
        </button>
      </div>

      <div className="w-full max-w-full">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md table-auto">
          <thead className="bg-gold">
            <tr>
              <th className="px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">First Name</th>
              <th className="px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Last Name</th>
              <th className="px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Email</th>
              <th className="px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Role</th>
              <th className="px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Vacation Hours</th>
              <th className="px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Department</th>
              <th className="px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {filteredUsers.map((user, index) => (
              <tr key={user.id} className={`hover:bg-gray-100 ${departmentColors[user.department] || ''}`}>
                <td className="px-2 py-4 whitespace-nowrap">
                  <input
                    type="text"
                    value={user.firstName}
                    onChange={(e) => handleInputChange(index, 'firstName', e.target.value)}
                    className="text-sm font-medium text-gray-900 border border-gray-300 rounded px-2 py-1"
                  />
                </td>
                <td className="px-2 py-4 whitespace-nowrap">
                  <input
                    type="text"
                    value={user.lastName}
                    onChange={(e) => handleInputChange(index, 'lastName', e.target.value)}
                    className="text-sm text-gray-900 border border-gray-300 rounded px-2 py-1"
                  />
                </td>
                <td className="px-2 py-4 whitespace-nowrap">
                  <input
                    type="email"
                    value={user.email}
                    onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                    className="text-sm text-gray-500 border border-gray-300 rounded px-2 py-1"
                  />
                </td>
                <td className="px-2 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{user.role}</div>
                </td>
                <td className="px-2 py-4 whitespace-nowrap">
                  <input
                    type="number"
                    value={user.vacation_hours}
                    onChange={(e) => handleInputChange(index, 'vacation_hours', e.target.value)}
                    className="text-sm text-gray-900 border border-gray-300 rounded px-2 py-1"
                  />
                </td>
                <td className="px-2 py-4 whitespace-nowrap">
                <select
                  value={departments.find(dept => dept.name === user.department)?.id || ''}
                  onChange={(e) => handleInputChange(index, 'department_id', e.target.value)}
                  className="text-sm text-gray-900 border border-gray-300 rounded px-2 py-1"
                >
                  <option value="">Select Department</option>
                  {departments.map((department) => (
                    <option key={department.id} value={department.id}>
                      {department.name}
                    </option>
                  ))}
                </select>
                </td>
                <td className="px-2 py-4 whitespace-nowrap flex space-x-2">
                  <button
                    onClick={() => handleSave(user.id, user)}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => {
                      setUserToDelete(user);
                      setIsDeleteModalOpen(true);
                    }}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for Delete Confirmation */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Confirm Deletion</h2>
            <p>Do you really want to delete the user '{userToDelete?.firstName} {userToDelete?.lastName}'?</p>
            <div className="flex justify-end space-x-4 mt-4">
              <button
                onClick={() => setIsDeleteModalOpen(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDelete(userToDelete.id)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Creating New User */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Create New User</h2>
            <div className="mb-4">
              <input
                type="text"
                placeholder="First Name"
                value={newUser.firstName}
                onChange={(e) => handleNewUserInputChange('firstName', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                placeholder="Last Name"
                value={newUser.lastName}
                onChange={(e) => handleNewUserInputChange('lastName', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
              />
            </div>
            <div className="mb-4">
              <input
                type="email"
                placeholder="Email"
                value={newUser.email}
                onChange={(e) => handleNewUserInputChange('email', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
              />
            </div>
            <div className="mb-4">
              <input
                type="number"
                placeholder="Vacation Hours"
                value={newUser.vacation_hours}
                onChange={(e) => handleNewUserInputChange('vacation_hours', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
              />
            </div>
            <div className="mb-4">
              <select
                value={newUser.department_id}
                onChange={(e) => handleNewUserInputChange('department_id', e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
              >
                <option value="">Select Department</option>
                {departments.map((department) => (
                  <option key={department.id} value={department.id}>
                    {department.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateUser}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Users;
