import React, { useState, useEffect } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';

const Layout = ({ handleLogout }) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setMenuOpen(false); // Fermer le menu si on repasse à un écran plus large
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const user = JSON.parse(localStorage.getItem('user'));
  const userRole = user ? user.role : null;

  const logout = () => {
    handleLogout();
    navigate('/login');
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* Navbar en haut en version mobile */}
      {isMobile ? (
        <div className="fixed top-0 left-0 w-full bg-gray-900 text-white flex items-center justify-between p-4 z-10">
          <div className="text-2xl font-bold">
            <span>OPUS</span> <span className="text-opusYellow">TRIPAK HR</span>
          </div>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-white text-3xl"
          >
            {menuOpen ? '✖' : '☰'}
          </button>
        </div>
      ) : (
        // Sidebar pour version desktop
        <div className="fixed left-0 top-0 w-1/5 h-full bg-gray-900 text-white p-6 flex flex-col justify-between">
          <div>
            <h2 className="text-3xl mb-6 font-bold">
              <span>OPUS</span> <span className="text-opusYellow">TRIPAK HR</span>
            </h2>
            <ul className="space-y-4">
              {userRole === 'Global Administrator' && (
                <>
                  <li>
                    <Link
                      to="/admin-approval"
                      className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                    >
                      Pending Holiday Request
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/planning"
                      className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                    >
                      Planning
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/sickness-logs"
                      className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                    >
                      Sickness Logs
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/users"
                      className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                    >
                      Users
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/departments"
                      className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                    >
                      Departments
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/allUsersListHolidays"
                      className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                    >
                      Archive of Holidays Requests
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/add-holidays"
                      className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                    >
                      Add Holiday Request
                    </Link>
                  </li>
                  <h4 className='p-0'>--------------------------------</h4>
                </>
              )}
              <>
                <li>
                  <Link
                    to="/holidays-request"
                    className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                  >
                    Holidays Requests
                  </Link>
                </li>
                <li>
                  <Link
                    to="/list-holidays-request"
                    className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                  >
                    List Holidays Requests
                  </Link>
                </li>
              </>
            </ul>
          </div>
          <button
            onClick={logout}
            className="mt-6 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
          >
            Logout
          </button>
        </div>
      )}

      {/* Menu déroulant pour version mobile */}
      {isMobile && menuOpen && (
        <div className="fixed top-16 left-0 w-full bg-gray-900 text-white z-10 p-6">
          <ul className="space-y-4">
            {userRole === 'Global Administrator' && (
              <>
                <li>
                  <Link
                    to="/admin-approval"
                    onClick={() => setMenuOpen(false)}
                    className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                  >
                    Pending Holiday Request
                  </Link>
                </li>
                <li>
                  <Link
                    to="/planning"
                    onClick={() => setMenuOpen(false)}
                    className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                  >
                    Planning
                  </Link>
                </li>
                <li>
                  <Link
                    to="/sickness-logs"
                    onClick={() => setMenuOpen(false)}
                    className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                  >
                    Sickness Logs
                  </Link>
                </li>
                <li>
                  <Link
                    to="/users"
                    onClick={() => setMenuOpen(false)}
                    className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                  >
                    Users
                  </Link>
                </li>
                <li>
                  <Link
                    to="/departments"
                    onClick={() => setMenuOpen(false)}
                    className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                  >
                    Departments
                  </Link>
                </li>
                <li>
                  <Link
                    to="/allUsersListHolidays"
                    onClick={() => setMenuOpen(false)}
                    className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                  >
                    Archive of Holidays Requests
                  </Link>
                </li>
                <li>
                  <Link
                    to="/add-holidays"
                    onClick={() => setMenuOpen(false)}
                    className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                  >
                    Add Holiday Request
                  </Link>
                </li>
              </>
            )}
            <>
              <li>
                <Link
                  to="/holidays-request"
                  onClick={() => setMenuOpen(false)}
                  className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                >
                  Holidays Requests
                </Link>
              </li>
              <li>
                <Link
                  to="/list-holidays-request"
                  onClick={() => setMenuOpen(false)}
                  className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
                >
                  List Holidays Requests
                </Link>
              </li>
            </>
            <li>
              <button
                onClick={logout}
                className="w-full bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
      )}

      {/* Contenu principal */}
      <div className={`${isMobile ? 'mt-16' : 'ml-[20%] w-[80%]'} p-6 bg-gray-100 min-h-screen`}>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
