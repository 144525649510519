import axios from 'axios';

const api = axios.create({
  baseURL: 'https://tripak.opus-international.co.uk/api',
});
//  baseURL: 'https://tripak.opus-international.co.uk/api',
//  baseURL: 'http://localhost:3963/api',

export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

export const login = (email, password) => {
  return api.post('/login', { email, password });
};

export const addHolidayRequest = (selectedUser, startDate, endDate, startDayType, endDayType) => {
  return api.post('/admin/add-holiday', { selectedUser, startDate, endDate, startDayType, endDayType });
}

// Nouvelle fonction pour récupérer les utilisateurs
export const fetchUsers = () => {
  return api.get('/admin/users');
};

// Nouvelle fonction pour créer un utilisateur
export const createUser = (userData) => {
  return api.post('/admin/users', userData);
};

export const updateUser = (id, updatedData) => {
  return api.put(`/admin/users/${id}`, updatedData);
};

export const deleteUser = (id) => {
  return api.delete(`/admin/users/${id}`);
}

// Demande de réinitialisation de mot de passe
export const requestResetPassword = (email) => {
  return api.post('/request-reset-password', { email });
};

// Réinitialisation du mot de passe avec le jeton
export const resetPassword = (token, password) => {
  return api.post(`/reset-password/${token}`, { password });
};

export const fetchUserHolidayRequests = () => {
  return api.get('/user/holiday-requests');
}

export const fetchVacationDays = () => {
  return api.get('/vacation-days');
};

export const fetchAllHolidayRequests = () => {
  return api.get('/admin/all-holiday-requests');
};

export const submitHolidayRequest = (startDate, endDate, startDayType = 'full', endDayType = 'full') => {
  return api.post('/vacation-request', { startDate, endDate, startDayType, endDayType });
};


export const fetchHolidayRequests = () => {
  return api.get('/admin/holiday-requests');
};

export const approveHolidayRequest = (id) => {
  return api.post(`/admin/holiday-requests/${id}/approve`);
};

export const cancelHolidayRequest = (id) => {
  return api.post(`/admin/holiday-requests/${id}/cancel`);
}

export const denyHolidayRequest = (id, reason) => {
  return api.post(`/admin/holiday-requests/${id}/deny`, { reason });
};

export const fetchDepartments = () => {
  return api.get('/departments');
};

// Créer un nouveau département
export const createDepartment = (departmentData) => {
  return api.post('/departments', departmentData);
};

// Mettre à jour un département existant
export const updateDepartment = (id, updatedData) => {
  return api.put(`/departments/${id}`, updatedData);
};

// Supprimer un département
export const deleteDepartment = (id) => {
  return api.delete(`/departments/${id}`);
};

export const fetchPlanning = async (startDate, endDate,department) => {
  const params = {
    startDate,
    endDate,
    department,
  };
  return await api.get('/holidays/planning', { params });
};
// Récupérer tous les sickness logs
export const fetchSicknessLogs = () => {
  return api.get('/sickness-logs');
};

// Créer un nouveau sickness log
export const createSicknessLog = (logData) => {
  return api.post('/sickness-logs', logData);
};

// Mettre à jour un sickness log existant
export const updateSicknessLog = (logId, updatedLogData) => {
  return api.put(`/sickness-logs/${logId}`, updatedLogData);
};

// Supprimer un sickness log
export const deleteSicknessLog = (logId) => {
  return api.delete(`/sickness-logs/${logId}`);
};

// Récupérer la liste des utilisateurs pour le menu déroulant
export const fetchUsersForDropdown = () => {
  return api.get('/users');
};

export default api;
