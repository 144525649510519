import React, { useState, useEffect } from 'react';
import { fetchAllHolidayRequests, cancelHolidayRequest } from './api'; // Ajoutez cancelHolidayRequest
import { toast } from 'react-toastify';

function AllUsersListHolidays() {
  const [requests, setRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredRequests, setFilteredRequests] = useState([]);

  const requestsPerPage = 20;

  useEffect(() => {
    const loadHolidayRequests = async () => {
      try {
        const response = await fetchAllHolidayRequests();
        const sortedRequests = response.data.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
        setRequests(sortedRequests);
        setFilteredRequests(sortedRequests);
      } catch (error) {
        toast.error('Failed to fetch holiday requests');
      }
    };

    loadHolidayRequests();
  }, []);

  const handleCancelRequest = async (id) => {
    if (window.confirm('Are you sure you want to cancel this holiday request?')) {
      try {
        await cancelHolidayRequest(id);
        toast.success('Holiday request successfully canceled');
        setRequests((prevRequests) =>
          prevRequests.filter((request) => request.id !== id)
        );
      } catch (error) {
        toast.error('Failed to cancel holiday request');
      }
    }
  };

  const formatDate = (dateString, dayType) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    if (dayType === 'morning') {
      return `${formattedDate} (Morning)`;
    } else if (dayType === 'afternoon') {
      return `${formattedDate} (Afternoon)`;
    }
    return formattedDate; // For full day
  };

  const getStatusText = (status) => {
    if (status === "accepted") return 'Approved';
    if (status === "rejected") return 'Denied';
    if (status === 'cancelled') return 'Cancelled';
    return 'Pending';
  };

  const calculateRequestedHours = (start, end, startDayType, endDayType) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    if (startDate.getTime() === endDate.getTime()) {
      return startDayType === 'morning' || startDayType === 'afternoon' ? 5 : 10;
    }

    let totalHours = 0;
    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
      const dayOfWeek = date.getDay();
      if (dayOfWeek >= 1 && dayOfWeek <= 4) {
        if (date.toDateString() === startDate.toDateString()) {
          totalHours += startDayType === 'morning' || startDayType === 'afternoon' ? 5 : 10;
        } else if (date.toDateString() === endDate.toDateString()) {
          totalHours += endDayType === 'morning' || endDayType === 'afternoon' ? 5 : 10;
        } else {
          totalHours += 10;
        }
      }
    }

    return totalHours;
  };

  useEffect(() => {
    const filtered = requests.filter(request =>
      request.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      request.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      request.department.toLowerCase().includes(searchTerm.toLowerCase()) ||
      getStatusText(request.status).toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRequests(filtered);
    setCurrentPage(1);
  }, [searchTerm, requests]);

  const indexOfLastRequest = currentPage * requestsPerPage;
  const indexOfFirstRequest = indexOfLastRequest - requestsPerPage;
  const currentRequests = filteredRequests.slice(indexOfFirstRequest, indexOfLastRequest);
  const totalPages = Math.ceil(filteredRequests.length / requestsPerPage);

  const goToNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const goToPreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-semibold mb-6 text-center">All Holiday Requests</h1>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by name, department, or status"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm"
        />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
          <thead className="bg-gold">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">First Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Last Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Department</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Start Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">End Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Requested Hours</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Reason (if denied)</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {currentRequests.map((request) => (
              <tr key={request.id} className="hover:bg-gray-100">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{request.firstName}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{request.lastName}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{request.department}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{formatDate(request.start_date, request.start_day_type)}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{formatDate(request.end_date, request.end_day_type)}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {calculateRequestedHours(request.start_date, request.end_date, request.start_day_type, request.end_day_type)} hours
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className={`text-sm font-semibold ${request.status === "accepted" ? 'text-green-500' : request.status === "rejected" ? 'text-red-500' : request.status === 'cancelled' ? 'text-blue-500' : 'text-yellow-500'}`}>
                    {getStatusText(request.status)}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{request.deny_reason || 'N/A'}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {request.status === "accepted" && (
                    <button
                      onClick={() => handleCancelRequest(request.id)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Cancel
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between items-center mt-4">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
        >
          Previous
        </button>
        <span className="text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default AllUsersListHolidays;
